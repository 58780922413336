import { Box, Container, Heading, Stack, Text } from 'atomic'
import { FC } from 'react'
import { pricesBooks, pricesPrints } from './PricesSection.css'
import PriceCard from '../pricecard/PriceCard'
import { BoxProps } from 'atomic/components/atoms/box/Box'
import Image from 'next/image'
import { ThemeColors, ouTheme } from 'atomic/styles/theme.css'
import { Trans } from 'next-i18next'
import { Product } from 'utils/get-store-data'
import { useCmsOrTranslation, useSafeTranslation } from 'hooks/useSafeTranslation'

import { useBackend } from 'context/BackendContext'
import { isBookProduct, isPrintProduct } from 'utils/get-store-data'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'

type CardColor = keyof ThemeColors

type AddOn = {
  label: string
  price: string
}
type PriceProps = {
  productType: 'book' | 'print'
  subtitle?: string
  customCoverImage?: boolean
  book27x27cmHardCoverImage?: string
  book20x20cmHardCoverImage?: string
  book20x20cmSoftCoverImage?: string
  semiGlossPaperImage?: string
  mattePaperImage?: string
  cardColors?: boolean
  largeHardCoverCardColor?: keyof ThemeColors
  mediumHardCoverCardColor?: keyof ThemeColors
  softCoverCardColor?: keyof ThemeColors
  semiGlossCardColor?: keyof ThemeColors
  matteCardColor?: keyof ThemeColors
} & BoxProps &
  BuilderProps

type ProductPriceDisplay = Product & {
  color: CardColor
  image: {
    alt: string
    src: string
  }
  priceInfo: {
    key: string
    value: string
  }
  addOns: {
    label: string
    price: string
  }[]
}

const PricesSection: FC<PriceProps> = ({
  title,
  subtitle,
  customCoverImage = false,
  book27x27cmHardCoverImage,
  book20x20cmHardCoverImage,
  book20x20cmSoftCoverImage,
  semiGlossPaperImage,
  mattePaperImage,
  cardColors = false,
  largeHardCoverCardColor = 'ljung3',
  mediumHardCoverCardColor = 'hjortron3',
  softCoverCardColor = 'blåbär3',
  semiGlossCardColor = 'ljung3',
  matteCardColor = 'hjortron3',
  ...props
}) => {
  const { t } = useSafeTranslation()
  const { cmsOrLokalise } = useCmsOrTranslation()
  const { storeData } = useBackend()

  const { productType = 'book' } = props
  const products = productType === 'book' ? storeData?.filter(isBookProduct) : storeData.filter(isPrintProduct)

  const getAddOns = (product: Product): AddOn[] => {
    switch (product.key) {
      case 'book-27x27cm-hard-cover': {
        return [
          { label: t('productpage_princing_format_hardcover_large_add_price_glossy_caption'), price: `${product.PricePerPage} ${product.currency}` },
          {
            label: t('productpage_princing_format_hardcover_large_add_price_silkmatte_caption'),
            price: `${product.additionalPricePerMattePage + product.PricePerPage} ${product.currency}`,
          },
        ]
      }
      case 'book-20x20cm-hard-cover': {
        return [
          { label: t('productpage_princing_format_hardcover_medium_add_price_glossy_caption'), price: `${product.PricePerPage} ${product.currency}` },
          {
            label: t('productpage_princing_format_hardcover_medium_add_price_silkmatte_caption'),
            price: `${product.additionalPricePerMattePage + product.PricePerPage} ${product.currency}`,
          },
        ]
      }
      case 'book-20x20cm-soft-cover': {
        return [
          { label: t('productpage_princing_format_softcover_medium_add_price_glossy_caption'), price: `${product.PricePerPage} ${product.currency}` },
          {
            label: t('productpage_princing_format_softcover_medium_add_price_silkmatte_caption'),
            price: `${product.additionalPricePerMattePage + product.PricePerPage} ${product.currency}`,
          },
        ]
      }
    }
  }
  const getImageUrl = (product: string): string => {
    switch (product) {
      case 'book-27x27cm-hard-cover': {
        return customCoverImage ? book27x27cmHardCoverImage : '/images/photobooks/pricing-info-hardcover-large.jpg'
      }
      case 'book-20x20cm-hard-cover': {
        return customCoverImage ? book20x20cmHardCoverImage : '/images/photobooks/pricing-info-hardcover-medium.jpg'
      }
      case 'book-20x20cm-soft-cover': {
        return customCoverImage ? book20x20cmSoftCoverImage : '/images/photobooks/pricing-info-softcover-medium.jpg'
      }
      case 'Glossy paper': {
        return customCoverImage ? semiGlossPaperImage : '/images/photoprints/print_glossy.jpg'
      }
      case 'Matte paper': {
        return customCoverImage ? mattePaperImage : '/images/photoprints/print_matte.jpg'
      }
      default: {
        return '/images/photobooks/pricing-info-hardcover-large.jpg'
      }
    }
  }

  // since we only have one product for prints, we need to duplicate it to get the correct display
  const tempProducts = productType === 'book' ? products : products ? [...products, ...products] : undefined

  const colors: CardColor[] = [
    cardColors && productType === 'book' ? largeHardCoverCardColor : cardColors && productType === 'print' ? semiGlossCardColor : 'ljung3',
    cardColors && productType === 'book' ? mediumHardCoverCardColor : cardColors && productType === 'print' ? matteCardColor : 'hjortron3',
    cardColors && productType === 'book' ? softCoverCardColor : 'blåbär3',
  ]

  const productDisplay: ProductPriceDisplay[] = tempProducts?.map((product, i) => {
    let imageurl: string
    let name: string
    let priceInfo: { key: string; value: string }
    let color: CardColor
    let addOns: AddOn[]

    if (productType === 'book') {
      color = colors[i]
      imageurl = getImageUrl(product.key)
      name = product.name
      priceInfo = { key: 'productpage_princing_format_hardcover_medium_base_price', value: `${product.price} ${product.currency}` }
      addOns = getAddOns(product)
    } else {
      color = colors[i]
      if (i === 0) {
        imageurl = getImageUrl('Glossy paper')
        name = t('productpage_prints_prices_glossy_subheading')
        priceInfo = { key: 'productpage_prints_subheading', value: `${product.price} ${product.currency}` }
        addOns = [
          {
            label: t('productpage_prints_prices_glossy_caption'),
            price: `${product.PricePerPage} ${product.currency}`,
          },
        ]
      } else if (i === 1) {
        imageurl = getImageUrl('Matte paper')
        name = t('productpage_prints_prices_matte_subheading')
        priceInfo = {
          key: 'productpage_prints_subheading',
          value: `${product.price + product.additionalPricePerMattePage * 10} ${product.currency}`,
        }
        addOns = [
          {
            label: t('productpage_prints_prices_matte_caption'),
            price: `${product.additionalPricePerMattePage + product.PricePerPage} ${product.currency}`,
          },
        ]
      }
    }
    const editedProduct = { ...product, name, color, priceInfo, addOns, image: { alt: product.name, src: imageurl } }

    return editedProduct
  })

  const description = subtitle ?? t('productpage_princing_body')

  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true, productType: true })

  return (
    <Container id="prices" paddingY="xxl" {...cleanProps} style={{ width: '100%' }}>
      <Heading as="h2" fontSize="xl" textAlign="center" marginBottom="xs">
        {cmsOrLokalise(title, 'productpage_princing_header')}
      </Heading>
      <Text textAlign="center" style={{ maxWidth: '70ch', marginInline: 'auto' }} as="p" marginBottom="xl">
        {productType === 'book'
          ? cmsOrLokalise(description, 'productpage_princing_body')
          : cmsOrLokalise(description, 'productpage_prints_prices_body')}
      </Text>
      <Box className={productType == 'book' ? pricesBooks : pricesPrints}>
        {productDisplay?.map((product, i) => (
          <PriceCard
            key={`${product.name}-${i}`}
            name={product.name}
            image={
              <Image
                fill
                sizes={`(max-width: ${ouTheme.breakpoints.tablet}) 100vw, 33vw`}
                style={{ objectFit: 'cover' }}
                alt={product.name}
                src={product.image.src}
              />
            }
            price={
              <Stack fontSize="s" direction="vertical" gap="xxxs">
                <Trans
                  key={product.priceInfo.key}
                  i18nKey={product.priceInfo.key}
                  values={{ price: product.priceInfo.value }}
                  components={{
                    price: (
                      <Text as="span" fontSize="xl" fontWeight="bold">
                        title
                      </Text>
                    ),
                  }}
                />
              </Stack>
            }
            color={product.color}
            addOns={product.addOns}
          />
        ))}
      </Box>
    </Container>
  )
}

export default PricesSection
